import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "search",
  standalone: true
})
export class SearchPipe implements PipeTransform {
  transform(value: any, args: any, type: any): any {
    if (args != null && args.trim && args.trim() != "") {
      let searchTerm = args.toLocaleLowerCase();
      if (type == "chatbotFilters-search") {
        return searchTerm ? value.filter((item) => item.display_text.toLocaleLowerCase().indexOf(searchTerm) != -1) : value;
      } else if (type == "shade-search") {
        return searchTerm ? value.filter((item) => item.product_name.toLocaleLowerCase().indexOf(searchTerm) != -1) : value;
      } else if (type == "question-search") {
        return searchTerm ? value.filter((item) => item.question.question.toLocaleLowerCase().indexOf(searchTerm) != -1) : value;
      } else if (type == "brand-search") {
        return searchTerm
          ? value?.filter(
              (item) =>
                item.name &&
                item.name
                  .replace(/[^A-Za-z0-9 ]/g, "")
                  .toLocaleLowerCase()
                  .indexOf(searchTerm.replace(/[^A-Za-z0-9 ]/g, "")) == 0
            )
          : value;
      } else if (type == "filter-search") {
        return searchTerm ? value?.filter((item) => typeof(item?.name) == 'string' && item?.name.toLocaleLowerCase()?.indexOf(searchTerm) != -1) : value;
      } else if (type == "highlight") {
        if (args && value) {
          let startIndex = value.toLowerCase().indexOf(args.toLowerCase());
          if (startIndex != -1) {
            let endLength = args.length;
            let matchingString = value.substr(startIndex, endLength);
            return value.replace(matchingString, "<span class='fw-normal'>" + matchingString + "</span>");
          }
        }
        return value;
      } else {
        return value;
      }
    } else {
      return value;
    }
  }
}
